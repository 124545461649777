<template>
  <section class="invoice-add-wrapper">
    <b-row
      v-if="invoice"
      class="invoice-add"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-form @submit.prevent>
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <img
                      src="@/assets/images/logo/logo.jpeg"
                      width="200px"
                      height="auto"
                    >
                    <h3 class="text-primary invoice-logo" />
                  </div>
                  <b-card-text class="mb-0">
                    Rue naplous 1002 tunis
                  </b-card-text>
                  <b-card-text class="mb-0">
                    Phone:+216 31 54 28 27
                  </b-card-text>
                  <b-card-text class="mb-0">
                    Fax:+216 31 54 28 27
                  </b-card-text>
                  <b-card-text class="mb-0">
                    Email:info@onesub.com.tn                </b-card-text>

                </div>

                <!-- Header: Right Content -->
                <div
                  v-if="invoice"
                  class="invoice-number-date mt-md-0 mt-2"
                >
                  <div class="d-flex align-items-center justify-content-md-end mb-1">
                    <h4 class="invoice-title">
                      Invoice
                    </h4>

                    <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="HashIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="invoice-data-id"
                        v-model="invoice.number"
                      />
                    </b-input-group>
                  </div>
                  <div class="d-flex align-items-center mb-1">
                    <span class="title">
                      Date:
                    </span>
                    <flat-pickr
                      v-model="invoiceData.date"
                      class="form-control invoice-edit-input"
                    />
                  </div>

                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Invoice Client & Payment Details -->
            <b-card-body
              class="invoice-padding pt-0"
            >
              <b-row class="invoice-spacing">

                <!-- Col: Invoice To -->
                <b-col
                  cols="12"
                  xl="6"
                  class="mb-lg-1"
                >
                  <h6 class="mb-2">
                    Supplier
                  </h6>
                  <!-- Select Client -->
                  <v-select
                    v-model="invoice.supplier"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="suppliers"
                    label="company_name"
                    input-id="invoice-data-client"
                    :clearable="false"
                  />

                  <!-- Selected Client -->
                  <div
                    v-if="invoice.supplier"
                    class="mt-1"
                  >
                    <h6
                      class="mb-25"
                    >
                      supplier : {{ invoice.supplier.company_name }}
                    </h6>
                    <b-card-text class="mb-25">
                      Adresse : {{ invoice.supplier.address }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      Email : {{ invoice.supplier.email }}
                    </b-card-text>

                  </div>
                </b-col>
                <feather-icon
                  size="16"
                  icon="XOctagonIcon"
                  class="cursor-pointer mt-4"
                  @click="invoice.customer=null"
                />

              </b-row>
            </b-card-body>
            <!-- Items Section -->
            <b-card-body class="invoice-padding form-item-section">
              <div
                ref="form"
                class="repeater-form"
                :style="{height: trHeight}"
              >
                <b-row
                  v-for="(item, index) in invoiceData.items"
                  :key="index"
                  ref="row"
                  class="pb-2"
                >
                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">

                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 px-1">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Item code
                        </b-col>
                        <b-col
                          cols="12"
                          lg="4"
                        >
                          Description
                        </b-col>

                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Quantity
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Cost price / CTN
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Total Cost price
                        </b-col>
                      </b-row>
                      <div class="form-item-action-col" />
                    </div>

                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Item</label>
                          <v-select
                            v-model="item.produit"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="itemsOptions"
                            label="item_code"
                            :clearable="false"
                            class="mb-2 item-selector-title"
                            placeholder="Select Item"
                            @input="val => updateItemForm(index, val)"
                          />

                        </b-col>
                        <b-col
                          cols="12"
                          lg="4"
                        >
                          <label class="d-inline d-lg-none">Description</label>
                          <b-form-input
                            v-model="item.produit.description"
                            class="mb-2"
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Qty</label>
                          <b-form-input
                            v-model="item.qty"
                            value="1"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Cost price / CTN</label>
                          <b-form-input
                            v-model="item.produit.pa_ctn_import"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Total Cost price
                          </label>
                          <p class="mb-1">
                            {{ (item.produit.pa_ctn_import * item.qty).toFixed(3) }}
                          </p>

                        </b-col>

                      </b-row>
                      <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItem(index)"
                        />

                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row
                  v-for="(item, id) in invoiceDataNew.items"
                  :key="id"
                  ref="row"
                  class="pb-2"
                >
                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">

                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 px-1">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Item code
                        </b-col>
                        <b-col
                          cols="12"
                          lg="4"
                        >
                          Description
                        </b-col>

                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Quantity
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Cost price / CTN

                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Cost price

                        </b-col>
                      </b-row>
                      <div class="form-item-action-col" />
                    </div>

                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Item</label>
                          <v-select
                            v-model="item.produit"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="itemsOptions"
                            label="item_code"
                            :clearable="false"
                            class="mb-2 item-selector-title"
                            placeholder="Select Item"
                            @input="val => updateItemForm(index, val)"
                          />

                        </b-col>
                        <b-col
                          cols="12"
                          lg="4"
                        >
                          <label class="d-inline d-lg-none">Desctiption</label>
                          <b-form-input
                            v-if="item.produit.item_code != ''"
                            v-model="item.produit.item_code"
                            class="mb-2"
                          />
                          <v-select
                            v-else
                            v-model="item.produit"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="itemsOptions"
                            label="name"
                            :clearable="false"
                            class="mb-2 item-selector-title"
                            placeholder="Select Item"
                            @input="val => updateItemForm(index, val)"
                          />
                        </b-col>

                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Qty</label>
                          <b-form-input
                            v-model="item.qty1"
                            value="1"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Cost price / CTN
                          </label>
                          <b-form-input
                            v-model="item.produit.pa_ctn_import"
                            value="1"
                            type="number"
                            class="mb-2"
                          />

                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Cost price
                          </label>
                          <p class="mb-1">
                            {{ (item.produit.pa_ctn_import * item.qty1).toFixed(3) }}
                          </p>

                        </b-col>

                      </b-row>
                      <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItem2(index)"
                        />

                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="addNewItemInItemForm"
              >
                Add product
              </b-button>
            </b-card-body>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>

                <!-- Col: Sales Persion -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                />

                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">

                    <hr class="my-50">
                    <div
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title">
                        Subtotal
                      </p>
                      <p class="invoice-total-amount">
                        {{ somme.toFixed(2) }}
                      </p>

                    </div>
                    <div
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title mr-1">
                        Freight
                      </p>
                      <b-input-group class="input-group-merge invoice-edit-input-group disabled">

                        <b-form-input
                          id="invoice-data-id"
                          v-model="invoiceData.freight"
                        />
                      </b-input-group>

                    </div>
                    <div
                      class="invoice-total-item mt-1"
                    >
                      <p class="invoice-total-title mr-1">
                        Other
                      </p>
                      <b-input-group class="input-group-merge invoice-edit-input-group disabled">

                        <b-form-input
                          id="invoice-data-id"
                          v-model="invoiceData.other"
                        />
                      </b-input-group>
                    </div>
                    <div
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title">
                        Total
                      </p>
                      <p class="invoice-total-amount">
                        {{ total }}
                      </p>

                    </div>
                  </div></b-col></b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">note  </span>
              <quill-editor
                ref="myQuillEditor"
                v-model="invoiceData.note"
                :options="editorOption"
                @blur="onEditorBlur($event)"
                @focus="onEditorFocus($event)"
                @ready="onEditorReady($event)"
              />
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >

        <!-- Action Buttons -->
        <b-card>

          <!-- Button: Send Invoice -->

          <!-- Button: DOwnload -->

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            @click="addInvoice"
          >
            Add Invoice
          </b-button>
        </b-card>

      </b-col>
    </b-row>
    <invoice-sidebar-add-new-customer />

  </section>

</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import qs from 'qs'
import { quillEditor } from 'vue-quill-editor'
import store from '@/store'
import invoiceStoreModule from './invoiceStoreModule'
import InvoiceSidebarAddNewCustomer from './AddClient.vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import axiosIns from '@/libs/axios'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    flatPickr,
    vSelect,
    InvoiceSidebarAddNewCustomer,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    quillEditor,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],
  data() {
    return {
      invoice: '',
    }
  },

  computed: {
    total() {
      return (Number(this.somme) + Number(this.invoiceData.freight) + Number(this.invoiceData.other)).toFixed(2)
    },
    somme() {
      const s = this.invoiceData.items.reduce((accumulator, item) => accumulator + (item.produit.pa_ctn_import * item.qty), 0)
      if (this.invoiceDataNew.length !== 0) {
        const n = this.invoiceDataNew.items.reduce((accumulator, item) => accumulator + (item.produit.pa_ctn_import * item.qty1), 0)

        return s + n
      }
      return s
    },
    invoicenum() {
      return this.invoices.length
    },
    generate() {
      return Number(this.invoicenum) + 1
    },

  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    this.getFacture()
    this.getProducts()
    this.getSuppliers()
    this.invoiceData.items.shift()
    this.invoiceDataNew.items.shift()

    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    // generateReport() {
    //   this.$refs.html2Pdf.generatePdf()
    // },
    async getProducts() {
      this.$http.get('/products/', { params: { is_deleted: false } }).then(response => {
        this.itemsOptions = response.data
      })
    },
    async getSuppliers() {
      this.$http.get('/suppliers/', { params: { is_deleted: false } }).then(response => {
        this.suppliers = response.data
      })
    },
    async getFacture() {
      // @ts-ignore
      const { id } = this.$route.params
      await this.$http.get(`/orders/detail/${id}`).then(response => {
        this.invoice = response.data
      })
      if (this.invoice != null) {
        const facture = this.invoice
        // eslint-disable-next-line no-plusplus
        this.$http.post(
          '/detail-products-order/',
          { order: this.invoice.id, products: this.invoice.products },
        ).then(res => {
          const result = res.data
          for (let i = 0; i < result.length; i += 1) {
            facture.products[i].pa_ctn_import = result[i].pa_ctn
            const itemFormBlankItem = {
              item: null,
              produit: facture.products[i],
              qty: result[i].quantity,
            }
            this.invoiceData.items.push(JSON.parse(JSON.stringify(itemFormBlankItem)))
          }
        })
      }
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    async addInvoice() {
      const products = this.invoiceData.items
      const productsNew = this.invoiceDataNew.items
      await axiosIns.post('purchase-invoices/create-all/', {
        reference: this.invoice.number,
        date: this.invoiceData.date,
        supplier: this.invoice.supplier.id,
        freight: this.invoiceData.freight,
        other: this.invoiceData.other,
        subtotal: this.somme.toFixed(2),
        total: this.total,
        note_payment: this.invoiceData.note,
        products,
        productsNew,
        order: this.$route.params.id,
      }).then(() => {
        this.$router.push({ name: 'purchase_invoices' })
        this.showToast('success', 'top-center', 'Purchase invoice successfully created')
      })
        .catch(error => {
          this.showToast('danger', 'top-center', error.toString())
        })
    },
    // async addInvoice() {
    //   const obj = {
    //     reference: this.invoice.number,
    //     date: this.invoiceData.date,
    //     note: this.invoiceData.note,
    //     supplier: this.invoice.supplier.id,
    //     freight: this.invoiceData.freight,
    //     other: this.invoiceData.other,
    //     subtotal: this.somme.toFixed(2),
    //     total: this.total,
    //     note_payment: this.invoiceData.note_payment,
    //   }

    //   await this.$http.post(
    //     '/purchase-invoices/create/',
    //     obj,
    //   ).then(response => {
    //     const products = this.invoiceData.items
    //     const productsNew = this.invoiceDataNew.items

    //     for (let p = 0; p < products.length; p += 1) {
    //       this.$http.post(
    //         '/purchase-invoices-products/create/',
    //         {
    //           product: products[p].produit.id,
    //           invoice: response.data.id,
    //           quantity: products[p].qty,
    //           price: products[p].produit.pa_ctn_import,
    //           total: products[p].qty * products[p].produit.pa_ctn_import,
    //         },
    //       )
    //     }
    //     for (let p = 0; p < productsNew.length; p += 1) {
    //       this.$http.post(
    //         '/purchase-invoices-products/create/',
    //         {
    //           product: productsNew[p].produit.id,
    //           invoice: response.data.id,
    //           quantity: productsNew[p].qty1,
    //           price: productsNew[p].produit.pa_ctn_import,
    //           total: productsNew[p].qty1 * productsNew[p].produit.pa_ctn_import,
    //         },
    //       )
    //     }
    //     this.$http
    //       .put(
    //         `/orders/update/${this.$route.params.id}/`,
    //         {
    //           is_invoiced: true,
    //         },
    //       )
    //     // this.generateReport()
    //     this.$router.push({
    //       name: 'purchase_invoices',
    //     })
    //     setTimeout(() => {
    //       this.showToast('success', 'top-center', 'Invoice successfully created')
    //     }, 1000)
    //   })
    // },

    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.invoiceDataNew.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItemNew)))

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    async removeItem(index) {
      this.invoiceData.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    removeItem2(index) {
      this.invoiceDataNew.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        // this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    onEditorBlur(quill) {
      console.log('editor blur!', quill)
    },
    onEditorFocus(quill) {
      console.log('editor focus!', quill)
    },
    onEditorReady(quill) {
      console.log('editor ready!', quill)
    },
    onEditorChange({ quill, html, text }) {
      console.log('editor change!', quill, html, text)
      this.content = html
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const suppliers = ref([])

    const itemFormBlankItem = {
      item: null,
      produit: {
        wholesale_price: 0,
        reference: '',
      },
      qty: 0,
    }
    const itemFormBlankItemNew = {
      item: null,
      produit: {
        wholesale_price: 0,
        reference: '',
      },
      qty1: 1,
    }
    const invoices = ref([])
    const current = new Date()

    const invoiceData = ref({
      id: '',
      client: null,
      date: `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],
      other: 0,
      freight: 0,
      salesPerson: '',
      note: '',
      paymentMethod: null,
    })
    const invoiceDataNew = ref({
      id: '',
      client: null,
      date: `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],
      other: 0,
      freight: 0,
      salesPerson: '',
      note: '',
      paymentMethod: null,
    })

    const itemsOptions = ref([])

    const remise = 0

    const updateItemForm = (index, val) => {
      const { cost, qty } = val
      invoiceData.value.items[index].cost = cost
      invoiceData.value.items[index].qty = qty
    }

    const paymentMethods = [
      'Bank Account',
      'PayPal',
      'UPI Transfer',
    ]

    return {
      invoiceData,
      invoiceDataNew,
      suppliers,
      itemsOptions,
      updateItemForm,
      itemFormBlankItemNew,
      itemFormBlankItem,
      paymentMethods,
      invoices,
      remise,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
